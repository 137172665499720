export const LOGIN = "https://cdn-server.arijit.dev/api/v1/user/login";
export const IS_LOGGED_IN =
  "https://cdn-server.arijit.dev/api/v1/user/isLoggedIn";
export const LIST_FOLDER = "https://cdn-server.arijit.dev/api/v1/folder/ls";
export const MAKE_FOLDER = "https://cdn-server.arijit.dev/api/v1/folder/mkdir";
export const DELETE_FOLDER =
  "https://cdn-server.arijit.dev/api/v1/folder/rmdir";
export const DOWNLOAD_FILE_URL =
  "https://cdn-server.arijit.dev/api/v1/file/download";
export const DELETE_FILE = "https://cdn-server.arijit.dev/api/v1/file/remove";
export const UPLOAD_FILE = "https://cdn-server.arijit.dev/api/v1/file/upload";
export const REGISTER = "https://cdn-server.arijit.dev/api/v1/user/register";
